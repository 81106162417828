import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getFiles({ organizationId, companyId, sectorId, workstationId }) {
	if (!organizationId || !companyId || !sectorId) return [];
	const params = { workstation_id: workstationId };
	const url = `/upload/${organizationId}/${companyId}/${sectorId}`;
	const { data } = await Api.get(url, { params });
	return data;
}

export function useFiles(payload) {
	const { organizationId, companyId, sectorId } = payload;
	const enabled = !!organizationId && !!companyId && !!sectorId;

	const files = useQuery(['files', payload], () => getFiles(payload), { enabled });
	return { files };
}
