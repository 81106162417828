import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/v1/Application/context';
import { FileSelect } from '@/components/ui/Inputs/FileSelect';
import { useFiles } from '@/hooks/v1/useFiles';

const { useFormInstance, useWatch } = Form;

export const Files: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const sectorId = useWatch('sector_id', form);
	const workstationId = useWatch('workstation_id', form);
	const fileId = useWatch('file_id', form);

	const { files: filesQuerie } = useFiles({
		organizationId: organization?.id,
		companyId: company?.id,
		sectorId,
		workstationId
	});

	const { data: files, isLoading, isFetching, isError } = filesQuerie;

	function handleFileSelect(id: string) {
		setFieldValue(['file_id'], id);
	}

	function handleClearFile() {
		setFieldValue(['file_id'], undefined);
	}

	return (
		<Col xs={24}>
			<Form.Item name={['file_id']} label={I18n.get('File')} labelCol={{ span: 24 }}>
				<FileSelect
					sectorId={sectorId}
					loading={isFetching}
					fileId={fileId}
					files={files}
					handleSelectFile={handleFileSelect}
					handleClearFile={handleClearFile}
					disabled={isLoading || isFetching || isError || !workstationId}
				/>
			</Form.Item>
		</Col>
	);
};
